:root{
    --theme-color:#0F1A20;
    --karpolan-color:#3AD4CE;
    --red-color:#FD040B;
    --border-color:#F0EDE8;
    --dark-gray:#776D5A;
    --light-blue:#7D82B8;
    --white-color:#FFFFFF;
    --light-gray:#F5F5F5;
    --dark-gray-700:#D9D9D9;
    --gray-400:#D2D2D2;
    --blue-gray:#8FABBA;
}

$theme-color:var(--theme-color);
$karpolan-color:var(--karpolan-color);
$red-color:var(--red-color);
$border:var(--border-color);
$dark-gray:var(--dark-gray);
$light-blue:var(--light-blue);
$white-color:var(--white-color);
$light-gary:var(--light-gray);
$dark-gray-700:var(--dark-gray-700);
$gray-400:var(--gray-400);
$blue-gray:var(--blue-gray);