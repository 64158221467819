@font-face {
    font-family: "Operetta12";
    src: url('../../../public/Fonts/Operetta12-Light.otf');
    font-weight: 400;
}
@font-face {
    font-family: "Operetta12";
    src: url('../../../public/Fonts/Operetta12-Medium.otf');
    font-weight: 500;
}
@font-face {
    font-family: "Operetta12";
    src: url('../../../public/Fonts/Operetta12-Regular.otf');
    font-weight: 600;
}
@font-face {
    font-family: "Operetta12";
    src: url('../../../public/Fonts/Operetta12-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: "LibreBaskerville";
    src: url('../../../public/Fonts/LibreBaskerville-Regular.ttf');
    
}
@font-face {
    font-family: "Taken-by-Vultures-Demo";
    src: url('../../../public/Fonts/Taken-by-Vultures-Demo.otf');
    
}