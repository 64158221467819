
@import './components/index.scss';
body{
    font-family: "Operetta12";
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


$padding-top:80px;

.heading{
    font-weight: 600;
    color: var(--karpolan-color);
    font-size: 50px;
    line-height: 60px;
    background-color: var(--white-color);  
    position: sticky;
    padding-top: 10PX;
    top: 110px;
    left: 0;
    margin-bottom: 0;

    @media screen and (max-width : 992px) {
        font-size: 30px;
        line-height: 37px;
        top: 98px;
    }
}
.link-tag{
    color: var(--red-color);
    font-size: 18px;
    font-weight: 700;
    font-family: "LibreBaskerville";
    line-height: 23.32px;
}
.all-heading-text{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    font-family: "LibreBaskerville";
}

.sub-title{
    font-family: "LibreBaskerville";
    font-size: 20px;
    color: var(--theme-color);

    span{
        font-size: 80px;
        line-height: 20px;
        font-weight: 400;

        @media screen and (max-width : 992px) {
            font-size: 40px;
        }
    }
}


header{ 
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
   border-bottom: 1px solid var(--border-color);
    nav {
        position: relative;
        padding: 20px 0;

        @media screen and (max-width : 992px) {
            padding: 20px 0 0;
        }
        .logo{
            position: relative;
            z-index: 3;
            // padding: 13px;
            border-bottom: 1px solid var(--border-color);
            @media screen and (max-width : 992px) {
              padding-bottom: 20px;
            }
          img{
            max-width: 120px;
          }
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            margin-bottom: 0;
            column-gap: 60px;

            li{
               
                 a{
                    text-decoration: none;
                    font-size: 20px;
                    color: var(--theme-color);
                    font-weight: 600;
                    line-height: 24px;
                    &:hover{
                        color: var(--red-color);
                    }

                    &.active-menu{
                        color: var(--red-color);
                    }

                    img{
                        width: 160px;
                        height: 70px;
                    }
                }

                
                @media screen and (max-width : 992px){
                    padding: 10px;
                    column-gap: 0px;
                    row-gap: 20px;
                }
            }

            @media screen and (max-width : 992px) {
                position: absolute;
                left: 0;
                top: 0;
                background-color: #fff;
                width: 100%;
                height: 100vh;
                flex-direction: column;
                padding: 0;
                transform: translateX(-100%);
                transition: all 0.5s ease-in-out;
                z-index: 2;
            }
        }
        
        #checkbox{
            display: none;
        }

        .click-box{
            display: none;
            position: absolute;
            right: 13px;
            top: 28px;
            @media screen and (max-width : 992px) {
                display: block;
                z-index: 3;
            }

        }
    }
}

#checkbox:checked ~ ul {
    transform: translateX(0);
}
.container{
    max-width: 1399px;
    width: 100%;
}
   
.hero{
    width: 100%;
    .immortal{
        background-image: url('../../public/Images/grid.svg');
        width: 100%;
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 100px;
        @media screen and (max-width : 1199px) {
            background-image: url('../../public/Images/grid-m.svg');
            background-size: contain;
        }
        @media screen and (max-width : 992px) {
            margin-top: 87px;
        }

    .hero-heading{
        h1{
            font-size: 80px;
            font-weight: 700;
            text-align: center;
            line-height: 90px;

            span{
                font-weight: 300;
                font-style: italic;
                display: block;
            }

            @media screen and (max-width : 1199px) {
                font-size: 40px;
                line-height: 50px;
            }
        }
    }
    }
    
}
.lacks-banner{
    background-image: url('../../public/Images/banner.svg');
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 40px;

    @media screen and (max-width : 992px) {
        height: 150px;
        padding: 0;
    }
    .lacks{
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        transform: translateY(60px);

        @media screen and (max-width : 992px) {
            transform: translateY(-20px);
        }
        
        .layer{
            transform: rotate(-7deg);
            @media screen and (max-width : 492px) {
                max-width: 140px;
                
            }
        }
    
        img{
            max-width: 400px;
            display: block;
            transform: rotate(5deg);
            @media screen and (max-width : 992px) {
                max-width: 200px;
                transform: rotate(0deg);
               
            }
        }
    }
}


// about css start 
.about{
  padding: 0 5px 0 85px;

  @media screen and (max-width : 992px) {
    padding: 0 5px;
  }
    .about-heading{
        border-left: 1px solid var(--border-color);
        padding-top: 80px;
       height: 100%;
        border-right: 1px solid var(--border-color);

        @media screen and (max-width : 992px) {
            padding-bottom: 40px;
        }
        h3{
            font-size: 50px;
            font-weight: 600;
            line-height: 60px;
            color: var(--karpolan-color);
        }
        p {
            padding-top: 50px;
            font-family: "LibreBaskerville";
            font-size: 20px;
            color: var(--theme-color);

            .head{
                font-size: 80px;
                line-height: 20px;
                font-weight: 400;
                @media screen and (max-width : 992px) {
                    font-size: 40px;
                }
            }
        }

        .tec{
            margin-top: 117px;
            transform: translate(-130px);
            @media screen and (min-width : 1400px) {
                transform: translate(0px);
            }
            @media screen and (max-width : 992px) {
                display: none;
            }
        }
        
    }
    .heal-img{
      
       padding: 80px 60px 0 85px;
        @media screen and (max-width : 992px) {
               padding: 0 0 40px 0;
                
        }

        .immortal{
            max-width: 490px;
            width: 100%;
            background-color: var(--white-color);
            padding: 40px 40px 0 0px;
            transform: translate(-105px, -210px);

            @media screen and (max-width : 1199px) {
                transform: translate(0,0);
                padding-right: 0;
                max-width: 100%;
                padding: 0px;
                padding-top: 40px;
            }
        

            p{
                font-size: 20px;
                font-family: "LibreBaskerville";
                color: var(--theme-color);
            }
            .tec-logy{
                width: 100%;
                display: none;
                @media screen and (max-width : 992px) {
                    display: block;
                    margin-top: 40px;
                }
            }
        }
    }
    .hela{
        background-image: url('../../public/Images/lela.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 712px;
        margin-top: 80px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 130px;

        @media screen and (max-width : 992px) {
            margin-top: 0;
        }

    }
    .hela-name{
        p{
            text-align: end;
            font-size: 10px;
            line-height: 18.24px;
            color: #000000;
            font-style: italic;
            font-family: "LibreBaskerville";
            font-weight: 400;
        }
    }
}
.price-box{
    display: flex;
    gap: 20px;
    overflow: hidden;
    transform: translateY(-110px);
    padding-bottom: 40px;
    padding-top: 5px;
    padding-left: 5px;
    @media screen and (max-width : 1199px) {
        transform: translateY(0px);
    }
    .boxes{
        display: flex;
        background-color: var(--white-color);
        padding: 20px;
        position: relative;
        // background: #FFF;
        box-shadow: 5px 5px 0px 0px rgba(30, 30, 30, 0.10), -5px -5px 0px 0px rgba(30, 30, 30, 0.10), 10px 10px 0px 0px rgba(58, 212, 206, 0.10);
        flex: 1 0 530px;
        padding: 40px 50px;

        @media screen and (max-width : 768px) {
            padding: 20px 30px;
            flex: 1 0 300px;
        }
        .icon-img{
            display: flex;
            flex-direction: column;
            margin-top: auto;
            @media screen and (max-width : 768px) {
                margin-bottom: auto;
                margin-top: 10px;
            }
        }
      
        .box-h{
            display: flex;
            flex-direction: column;
            h3{
                font-size: 50px;
                color: var(--theme-color);
                padding-bottom: 50px;
                font-weight: 700;
                @media screen and (max-width : 768px) {
                    padding-bottom: 0px;
                    font-size: 30px;
                }

                span{
                    display: block;
                }
            }
            p{
                font-size: 20px;
                font-family: "LibreBaskerville";
                color: var(--theme-color);
                font-weight: 400;
                margin-top: auto;
                padding-right: 10px;
                @media screen and (max-width : 768px) {
                   
                    font-size: 15px;
                }
            }
           
        }
        img{
            @media screen and (max-width : 768px) {
                max-width: 75px;
                width: 100%;
            }
        }

         &::before,
         &::after{
            background-color: #fff;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            width: 10px;
         }


    }
    .boxes::after {
        bottom: -2px;
        left:  -2px;
      }
      .boxes::before {
        top: -2px;
        right: -2px;
      }
}

// about css end 

// join us css start

.join-us{
        position: relative;
        width: 100%;
       

    .line-img{
        padding: 0;
        position: absolute;
        top: 50px;
        z-index: -1;
        width: 100%;
        img{
            width: 100% !important;
        }

        @media screen and (max-width : 992px) {
            position: static;
            top: 0;
        }
    }

    .hela-v{
        img{
            width: 100%;

            @media screen and (max-width : 992px) {
                padding-top: 40px;
            }
        }
    }
    .history-hela{
        height: 100%;
       

        @media screen and (max-width : 992px) {
            text-align: center;
            // margin-bottom: 40px;
            padding: 0 0px;
        }
        p{
            font-size: 24px;
            max-width: 495px;
            font-weight: 400;
            line-height: 29.76px;
            font-family: "LibreBaskerville";
            padding-left: 60px;

            @media screen and (max-width : 992px) {
                max-width: 100%;
                text-align: center;
                padding: 20px 20px 10px 20px;
            }
        }
        .join-logo{
            padding-bottom: 70px;
            padding-left: 54px;
            @media screen and (max-width : 992px) {
                
                padding-left: 0;
            }
        }
     
    }
   .join-this{
    background-image: url('../../public/Images/join-us.svg');
    display: flex;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 60px;
    transform: translateY(-5px);
    @media screen and (max-width : 992px) {      
        display: flex;
        justify-content: center;
    }
    // @media screen and (min-width : 1400px) {
    //     min-height: 300px;
    // }

    .join-logo-item{
        max-width: 450px;
        transform: translateY(6px);

        @media screen and (max-width : 992px) {
            max-width: 100%;
        }
    }
   }
}

// join us css end

// event css start
.event{
    padding: 80px;

    @media screen and (max-width : 992px) {
        padding: 40px 20px;
    }

    .event-heading{
       padding-bottom: 40px;
    }
    .card{
        background-color: var(--light-gray);
        border: 0;
        padding: 40px;
        border-radius: 0;
        @media screen and (max-width : 992px) {
            padding: 20px;
        }

        &.card-light{
            transform: translateY(60px);
            @media screen and (max-width : 992px) {
                transform: translateY(0px);
                margin-top: 40px;
            }
        }

        .card-img{
            width: 180px;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--dark-gray-700);
            border-radius: 0;
           margin-bottom: 30px;

           @media screen and (max-width : 992px) {
            width: 100%;
           }

            img{
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }

        .card-body{
            padding: 0;

            .h-c{
                font-size: 20px;
                font-family: "LibreBaskerville";
                font-weight: 700;
                color: var(--theme-color);
                margin-bottom: 0;
                padding-bottom: 20px;
            }
            .c-t{
                font-size: 16px;
                font-family: "LibreBaskerville";
                font-weight: 400;
                color: var(--theme-color);
                line-height: 24px;
                border-bottom: 1px solid var(--gray-400);
                padding-bottom: 20px;
            }
            .resort{
                display: flex;
                padding-top: 20px;

                @media screen and (max-width : 992px) {
                    flex-direction: column;
                }

                h3{
                    font-size: 16px;
                    font-family: "LibreBaskerville";
                    font-weight: 700;
                    color: var(--theme-color);
                    margin-bottom: 0;
                    padding-bottom: 20px;
                    line-height: 24px;
                }
                p{
                    font-size: 12px;
                    color: var(--theme-color);
                    font-weight: 400;
                    font-family: "LibreBaskerville";
                    line-height: 18px;
                    margin-bottom: 0;
                    padding-bottom: 20px;
                }
            }
            a{
                color: var(--red-color);
                font-size: 18px;
                font-weight: 700;
                font-family: "LibreBaskerville";
                line-height: 23.32px;
            }
        }
    }
}

// event css end

// what are css start 
.what-are{
    padding-top: 80px;
    padding-bottom: 1px;

    @media screen and (max-width : 992px) {
        padding: 10px 5px 0;
    }

    .w-r{
        background-color: var(--red-color);
        width: 100%;
        min-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../../public/Images/He-La.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 470px;

        @media screen and (max-width : 992px) {
            min-height: 400px;
            background-size: 260px;
        }

        h3{
            font-size: 80px;
            font-weight: 600;
            color: var(--karpolan-color);
            line-height: 104px;
            text-align: center;
            span{
                display: block;
                font-weight: 400;
                font-family: "Taken-by-Vultures-Demo";
            }
        }
    }
    .video-hela{
        background-image: url('../../public/Images/video-banner.png');
        width: 100%;
        height: 700px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #122529e7;
        }

        @media screen and (max-width : 992px) {
            height: 400px;
        }

        .play-icon{
            position: relative;
            z-index: 1;
            
            // width: 100px;
            // height: 100px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            background-color: red;
            // img{
            //     width: 100%;
            //     border-radius: 50%;
            //     animation-name: ripple;
            //     animation-duration: 1.5s;
            //     animation-iteration-count: infinite;
            //     animation-fill-mode: both;
            // }
        }
       .play-click{
        background-color: #1e3440;
        width: 120px;
        height: 120px;
        display: flex;
        border: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        border-radius: 50%;
        animation: ripple 0.8s ease-in-out infinite;
       }
        @keyframes ripple {

            0% {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0);
               
    
            }
        
            100% {
                box-shadow: 0 0 0 40px #fff1, 0 0 0 40px #fff1;
               
            }
        }

        iframe{
            width: 100%;
           height: 100%;
           position: relative;
           z-index: 1;
        }
        video{
            width: 100%;
           height: 100%;
           position: relative;
           z-index: 1;
        }
    }
}

// what are css end

marquee {
    border-bottom: 2px solid var(--border-color);
    padding: 20px 0;
    span{
        font-size: 50px;
        font-weight: 400;
       padding-right: 60px;
        color: #000000;
    }
}

.henrietta{
    padding:100px 80px;

    @media screen and (max-width : 992px) {
        padding: 40px 8px;
    }

    img{
        width: 100%;
        margin-bottom: 20px;
    }
}


// our mission css start

.our-mission{
    padding-left: 80px;
    padding-right: 80px;

    @media screen and (max-width : 992px) {
        padding: 0 5px;
    }

    .mission-hela{
        border-left: 1px solid var(--border-color);
        padding-top: 80px;
        transform: translateY(-9px);
        height: 100%;
        border-right: 1px solid var(--border-color);
        padding-bottom: 80px;

        @media screen and (max-width : 992px) {
            padding-bottom: 40px;
        }
        
        h3{
            margin-bottom: 0;
           
        }
        .com{
            padding-top: 200px;
            @media screen and (max-width : 992px) {  
                padding-top: 40px;
            }
        }
    }
    .miss-hela{
        padding-top:140px;
        padding-left: 35px;
        @media screen and (max-width : 992px) {
            padding: 0;
            padding-left: 0;
        }

        img{
            width: 100%;
        }

        p{
            text-align: end;
            font-size: 10px;
            line-height: 18.24px;
            color: #000000;
            font-style: italic;
            font-family: "LibreBaskerville";
            font-weight: 400;
        }
    }
    .blood{
        transform: translateY(-9px);
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
        padding-bottom: 80px;

        img{
            width: 100%;
        }

        p{
            font-size: 10px;
            line-height: 18.24px;
            color: #000000;
            font-style: italic;
            font-family: "LibreBaskerville";
            font-weight: 400;
        }
    }
    .our-vision{
        // transform: translateY(-100px);
        padding-left: 35px;

        @media screen and (max-width :1199px) {
            transform: translateY(0);
            padding-top: 20px;
            padding-bottom: 40px;
            padding-left: 0;
        }

        h3{
            background-color: var(--white-color);   
        }
        p{
            padding-top: 150px;
            @media screen and (max-width : 992px) {
                padding-top: 40px;
            }
        }

        ul{
            padding: 0 20px;
            li{
                @extend .sub-title;
                padding-bottom: 10px;
            }
        }
    }
}
.line-new{
    margin: 0;
    background-color: #c7c5c1;
    transform: translateY(-79px);
}

.line-new-2{
    margin: 0;
    background-color: #c7c5c1;
    transform: translateY(-9px);
}

.programs{
    padding-top: 150px !important;

    @media screen and (max-width : 992px) {
        padding-top: 0px !important;
    }
}
.services-blood{
    padding-top: 170px;
    padding-bottom: 30px;
    padding-left: 35px;

    @media screen and (max-width : 992px) {
        padding-top: 0px;
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
        transform: translateY(-9px);
        padding-left: 0;
    }
    .s-b{
        font-family: "LibreBaskerville";
        color: var(--theme-color);
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 50px;

        &.s-b-r{
            border-bottom: 0;
        }
        h3{
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            
        }
        p{
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 0;
            padding-bottom: 50px;
            // @media screen and (max-width : 992px){
            //     padding-bottom: 20px;
            // }
        }
        a{
            color: var(--red-color);
            font-weight: 700;
        }
    }
    
}

// our mission css end

// hbo css start 

.hbo{
    background-color: var(--theme-color);
    padding: 40px 0;
    transform: translateY(-9px);
    position: relative;

    .hbo-film{
        padding-left: 80px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        flex: 0 0 450px;
        column-gap: 50px;
        @media screen and (max-width : 992px) {
            padding: 20px 8px;
        }
        img{
            max-width: 200px;
        }
        h4{
            font-size: 50px;
            color: var(--karpolan-color);
            font-weight: 600;
            line-height: 60px;

            @media screen and (max-width : 992px) {
                font-size: 30px;
                line-height: 40px;
            }
        }
        p{
            font-size: 24px;
            color: var(--white-color);
            font-weight: 400;
            line-height: 28.8px;
        }
        .arrow-button{
            display: flex;
            margin-top: 100px;
            column-gap: 30px;
            align-items: center;
            @media screen and (max-width : 992px) {
                margin-top: 30px;
                margin-bottom: 40px;
            }
            button{
                background-color: transparent;
                border: 0;

                img{
                    width: 20px;
                }
            }
        }

    }
    
    .c-card{
        img{
            width: 100%;
            height: 400px;
            object-fit: cover;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            font-family: "LibreBaskerville";
            line-height: 24px;
            color: var(--blue-gray);
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 0;
        }
    }
}

.react-multi-carousel-track{
    column-gap: 20px;
    
}
.react-multiple-carousel__arrow{
    display: none;
}

 
// hbo css end 

// press css start 
.press{
    padding: 80px;

    @media screen and (max-width : 992px) {
       padding: 40px 8px;
    }

    .v-card{
        padding-top: 150px;

        @media screen and (max-width : 992px) {
            padding-top: 40px;
        }

        .health-card{
            display: flex;
            column-gap: 40px;
            margin-bottom: 70px;
            @media screen and (max-width : 768px) {
                flex-direction: column;
                margin-bottom: 40px;
            }
            .dr-img{
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 300px;
                height: 300px;
                overflow: hidden;

                @media screen and (max-width : 768px) {
                    justify-content: flex-start;
        
                }

                img{
                    width: 100%;
                }
            }
            .health-content{
                padding-top: 50px;

                @media screen and (max-width : 768px) {
                    padding-top: 20px;
                }
                h3{
                    padding-bottom: 12px;
                }
                p{
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--theme-color);
                    font-family: "LibreBaskerville";
                }
            }
        }
    }
}

// press css end

// group-img css start

.group-img{
    padding:0 80px;

    @media screen and (max-width : 992px) {
        padding: 0px 8px;
    }

    .all-member{
        // background: url("../../public/Images/Rectangle-31.png") lightgray 50% / cover no-repeat;
        // height: 606px;
        // width: 100%;
        // padding-top: 600px;
        img{
            width: 100%;
        }

        .consortium{
            background-color: var(--white-color);
            max-width: 500px;
            margin-left: auto;
            box-shadow: -3px 3px 0px 0px rgba(58, 212, 206, 0.10);
            margin-right: 30px;
            padding: 20px 0;
          
            @media screen and (max-width : 992px) {
                margin-right: 0;
                max-width: 100%;
                box-shadow: none;
            }
        }
        h3{
            font-size: 120px;
            font-weight: 400;
            color: var(--theme-color);
            font-family: "Taken-by-Vultures-Demo";
            line-height: 180px;
            letter-spacing: 11px;
            padding-left: 80px;
            @media screen and (max-width : 992px) {
               padding-left: 20px;
               font-size: 80px;
               line-height: 140px;
            }
        }
        h4{
            font-size: 50px;
            color: var(--theme-color);
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 0;
            padding-bottom: 26px;
            padding-left: 50px;

            @media screen and (max-width : 992px) {
                font-size: 25px;
                padding-left: 0px;
            }
        }

        p{
            font-size: 16px;
            font-weight: 400;
            color: var(--theme-color);
            line-height: 24px;
            font-family: "LibreBaskerville";
            padding-bottom: 74px;
            margin-bottom: 0;
            border-bottom: 1px solid var(--border-color);
            padding-left: 50px;
            @media screen and (max-width : 992px) {
                padding-bottom: 0;
                border-bottom: 0;
                padding-left: 0px;
            }
        }

        h5{
            font-size: 30px;
            font-weight: 600;
            color: var(--theme-color);
            line-height: 36px;
            padding-left: 50px;
            padding-top: 50px;
            padding-bottom: 22px;
            margin-bottom: 0;

            @media screen and (max-width : 992px) {
                padding-left: 0px;
                font-size: 25px;
                padding-top: 0;
            }
            
        }
        ul{
            padding-left: 70px;
            column-count: 2;

            @media screen and (max-width : 992px) {
                column-count: 1;
                padding-left: 20px;
            }
            li{
                font-family: "LibreBaskerville";
                font-size: 18px;
                font-weight: 400;
                color: var(--theme-color);
                line-height: 32.11px;
            }
        }
        a{
            padding-left: 50px;
            padding-top: 80px;
            display: block;

            @media screen and (max-width : 992px) {
                padding-left: 0px;
                padding-top: 20px;
                display: block;
            }
        }
    }
}

// group-img css end

// social-feeds css start 

.social-feeds{
    padding: 0 80px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width : 992px) {
        padding: 0 8px;
    }

    .s-f{
        border-left: 1px solid var(--border-color);
        padding-top: 20px;
    }
}

.social-img{
    padding: 70px 80px;
    @media screen and (max-width : 992px) {
        padding: 20px 8px;
    }
}

// social-feeds css end 

// footer css start
footer{
    background-color: #B9B9B91A;
    width: 100%;
    height: 400px;
    padding: 60px 55px 100px;
    margin-bottom: 100px;


    @media screen and (max-width : 992px) {
        padding: 20px 8px;
        margin-top: 40px;
    }

    .address{
        @media screen and (max-width : 992px) {
          text-align: center;
        }

        img{
            max-width: 270px;
            width: 100%;
            margin-bottom: 74px;
        }

        address{
           color: var(--theme-color);
           font-size: 14px;
           font-weight: 400;
           line-height: 24px;
           font-family: "LibreBaskerville";
           padding-left: 20px;

           @media screen and (max-width : 992px) {
            padding-left: 0;
           }
        }

        p{
            padding-top: 160px;
            font-size: 16px;
            font-weight: 400;
            font-family: "LibreBaskerville";
            color: var(--theme-color);
            @media screen and (max-width : 992px) {
                padding-top: 20px;
              }
        }
    }

    .contact-form{
        margin-bottom: 100px;
        @media screen and (max-width : 992px) {
            padding-top: 40px;
          }
        h3{
            font-size: 80px;
            font-weight: 400;
            color: var(--theme-color);
            letter-spacing: 4px;
            font-family: "Taken-by-Vultures-Demo";
        }

        .form-contact{
            background-color: var(--karpolan-color);
            padding: 48px 40px;
            margin-top: 12px;
            width: 100%;

            .f-f{
                position: relative;
                    label{
                    position: absolute;
                    top: 7px;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 27.28px;
                    font-family: "LibreBaskerville";
                    width: 190px;
                    @media screen and (max-width : 992px) {
                        position: static;
                    }
                    
                    &::before{
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 1px;
                        height: 46px;
                        background-color: var(--white-color);
                        transform: translateY(-17px);
                        @media screen and (max-width : 992px) {
                           background-color: transparent;
                        }
                       
                    }
                    
                    &.text-l{
                    
                        &::before{
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 1px;
                            height: 96px;
                            background-color: var(--white-color);
                            transform: translateY(-17px);
                            @media screen and (max-width : 992px) {
                               background-color: transparent;
                            }
                           
                        }
                    }
                }

                .form-control{

                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 1px solid var(--white-color);
                    font-size: 20px;
                    color: var(--white-color);
                    font-family: "LibreBaskerville";
                    line-height: 24.8px;

                    @media screen and (min-width : 992px) {
                        padding-left: 200px;
                     }
                    &:focus{
                        box-shadow: none;

                    }
                }
            }
            .sub-mit{
                background-color: var(--theme-color);
                padding: 13px 65px;
                color: var(--karpolan-color);
                font-weight: 700;
                font-family: "LibreBaskerville";
                font-size: 18px;
                margin-top: 48px;
                
            }
        }
    }
}

// footer css end